import React from 'react'
const Articles = React.lazy(() => import('../../component/pages/eng/Articles'))
const Gender = React.lazy(() => import('../../component/pages/eng/Gender'))
const Number = React.lazy(() => import('../../component/pages/eng/Number'))
const Prepositions = React.lazy(() => import('../../component/pages/eng/Prepositions'))
const SimpleSentence = React.lazy(() => import('../../component/pages/eng/SimpleSentence'))
const VoiceChange = React.lazy(() => import('../../component/pages/eng/VoiceChange'))

// import Articles from '../../component/pages/eng/Articles'



export default function pageRegister_eng() {
  return [
    {
      link: '/articles',
      name: 'Articles',
      component: <Articles />
    },
    {
      link: '/gender',
      name: 'Gender',
      component: <Gender />
    },
    {
      link: '/number',
      name: 'Number',
      component: <Number />
    },
    {
      link: '/prepositions ',
      name: 'Prepositions ',
      component: <Prepositions />
    },
    {
      link: '/simple-sentence',
      name: 'Simple Sentence',
      component: <SimpleSentence />
    },
    {
      link: '/voice-change',
      name: 'Voice Change',
      component: <VoiceChange />
    },
    
  ]
}
