import theme from "./theme";
import langCodes from "./langCodes";
import strings from "./strings";
import dimension from "./dimension";
import deviceType from "../lib/deviceType";

let config = {}

config.theme = theme
config.langCodes = langCodes
config.strings = strings
config.dimension = dimension
config.device = deviceType()

// console.log(window.screen.availHeight - 66)
// console.log(config.dimension)
// console.log(config)



export default config;