const strings = {
    faceAndPlaceValue: {
        beng: '',
        eng: ''
    },
    faceAndPlaceValue: {
        beng: '',
        eng: ''
    },
    faceAndPlaceValue: {
        beng: '',
        eng: ''
    },
}
export default strings;