import '../../../css/color.css'
import '../../../css/WebNavigation.css'
import React, { createRef, useRef, useState, useReducer, useContext } from 'react';
import { Link } from "react-router-dom";
import { GlobalStateContext } from '../../../AppContext';
import MathPages from './MathPages';
import EngPages from './EngPages';
import Settings from "./Settings";
import Accordion from '../../Accordion'

export default function WebNavigationV2(props) {

    const globalStateContext = useContext(GlobalStateContext)
    let currentState = globalStateContext.cState
    let dispatch = globalStateContext.dispatchDef

    const accordion = [
        {
            header:'Arithmetic',
            body:<MathPages />
        },
        // {
        //     header:'English',
        //     body:<EngPages />
        // },
        {
            header:'Settings',
            body:<Settings/>
        }
    ]
    return (
        <>
            <div className="header bg-custom">
                {currentState.websiteName}
            </div>
            <input type="checkbox" name="" id="open-sidebar-menu"></input>
            <label htmlFor="open-sidebar-menu" className="sidebar-icon-toggle">
                <div className="spinner top"></div>
                <div className="spinner middle"></div>
                <div className="spinner bottom"></div>
            </label>
            <div id="sidebarMenu" className='shadow-lg'>
                <Accordion data={accordion} />
                {/* <MathPages /> */}
            </div>
        </>
    );
}