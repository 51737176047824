export const stateHandeler = (initialState, localState) => {
    const newState = {}
    if (localState != null) {
        for (const key in initialState) {
            if (localState.hasOwnProperty(key)) {
                newState[`${key}`] = localState[`${key}`]
            } else {
                newState[`${key}`] = initialState[`${key}`]
            }
        }
        return newState;
    } else {
        return initialState;
    }
}

export function findStateIndex(state, inputBoxId) {
    let targetIndex = null
    if (Array.isArray(state) === true) {
        state.filter((e, i) => {
            if (e.id === inputBoxId) {
                targetIndex = i
            }
        })
        return targetIndex
    } else {
        console.log('findStateIndex: Parameter "state" must be an array.')
    }
}

export function partOfState(state, id) {
    if (findStateIndex(state, id) != null) {
        return state[findStateIndex(state, id)]
    } else {
        return undefined
    }
}

