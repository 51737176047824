const initialState =
{
  websiteName: "PrimaryMath.in",
  rememberHomePageScrollPosition: 0,
  lastAccessedRoute: null,
  vibrationStrength: '0',
  audioVolume: '0',
  lang: 'bn',
  leftMenu: '',
  settingMenuVisibility: 'm',
  navigationStyle: null,
  settingModalVisibility: 0,

  activeItemInAppBottomBar: 'math',

  additionOneDigitPageNo: 1,
  additionTwoDigitPageNo: 1,
  additionThreeDigitPageNo: 1,
  additionFourDigitPageNo: 1,

  substractionOneDigitPageNo: 1,
  substractionTwoDigitPageNo: 1,
  substractionThreeDigitPageNo: 1,
  substractionFourDigitPageNo: 1,

  multiTable: 1,
  multiOneDigitPageNo: 1,
  multiTwoDigitPageNo: 1,
  multiThreeDigitPageNo: 1,

  divisionOnlyPageNo: 1,
  divisionAndRemainderPageNo: 1,

  ascendingTwoDigitPageNo: 1,
  ascendingThreeDigitPageNo: 1,
  ascendingFourDigitPageNo: 1,
  ascendingFiveDigitPageNo: 1,

  hourMinuteAdditionPageNo: 1,
  currencyAdditionPageNo: 1,

  lcmPageNo: 1,

  frAddSimplePageNo: 1,
  frAddPageNo: 1,
  frSubSimplePageNo: 1,
  frSubPageNo: 1,
  frAscendingPageNo: 1,
  frDescendingPageNo: 1,
  frImproperToMixPageNo: 1,
  frMixToImproperPageNo: 1,

  genderPage: 1,
  numberPage: 1,
  simpleSentencePage: 1,
  voiceChangePage: 1,
}
export default initialState;