// import Home from "../../component/pages/Home";
// import PageCard from "../../component/navigation/app/FirstPage";
// import ClockPage from "../../component/pages/ClockPage";
// // import WordToNumPage from "../../component/pages/WordToNumPage";
// // import NumberToWordPage from "../../component/pages/NumberToWordPage";
// import CreateSmallestBiggestNumPage from "../../component/pages/CreateSmallestBiggestNumPage";
// import FaceAndPlaceValuePage from "../component/pages/FaceAndPlaceValuePage";
// import NumberProblemPage from "../component/pages/NumberProblemPage";
// import SeriesPage from "../component/pages/SeriesPage";
// import AdditionSimplePage from "../component/pages/AdditionSimplePage";
// import AdditionTwoDigitPage from "../component/pages/AdditionTwoDigitPage";
// import AdditionThreeDigitPage from "../component/pages/AdditionThreeDigitPage";
// import AdditionFourDigitPage from "../component/pages/AdditionFourDigitPage";
// import AdditionAfterArrangePage from "../component/pages/AdditionAfterArrangePage";
// import SubSimplePage from "../component/pages/SubSimplePage";
// import SubTwoDigitPage from "../component/pages/SubTwoDigitPage";
// import SubThreeDigitPage from "../component/pages/SubThreeDigitPage";
// import SubFourDigitPage from "../component/pages/SubFourDigitPage";
// import SubAfterArrangePage from "../component/pages/SubAfterArrangePage";
// import MultiSimplePage from "../component/pages/MultiSimplePage";
// import MultiTwoDigitPage from "../component/pages/MultiTwoDigitPage";
// import MultiThreeDigitPage from "../component/pages/MultiThreeDigitPage";
// import MultiFourDigitPage from "../component/pages/MultiFourDigitPage";
// import MultiAfterArrangePage from "../component/pages/MultiAfterArrangePage";
// import DivisionSimplePage from "../component/pages/DivisionSimplePage";
// import DivisionAndRemainderPage from "../component/pages/DivisionAndRemainderPage";
// import EvenNumberPage from "../component/pages/EvenNumberPage";
// import OddNumberPage from "../component/pages/OddNumberPage";
// import DivisibleBy2 from "../component/pages/DivisibleBy2";
// import DivisibleBy3 from "../component/pages/DivisibleBy3";
// import DivisibleBy5 from "../component/pages/DivisibleBy5";
// import AscendingTwoDigitPage from "../component/pages/AscendingTwoDigitPage";
// import AscendingThreeDigitPage from "../component/pages/AscendingThreeDigitPage";
// import AscendingFourDigitPage from "../component/pages/AscendingFourDigitPage";
// import AscendingFiveDigitPage from "../component/pages/AscendingFiveDigitPage";
// import DescendingTwoDigitPage from "../component/pages/DescendingTwoDigitPage";
// import DescendingThreeDigitPage from "../component/pages/DescendingThreeDigitPage";
// import DescendingFourDigitPage from "../component/pages/DescendingFourDigitPage";
// import DescendingFiveDigitPage from "../component/pages/DescendingFiveDigitPage";
// import FrAdditionPage from "../component/pages/FrAdditionPage";
// import FrSubPage from "../component/pages/FrSubPage";
// import FrMixedToImproper from "../component/pages/FrMixedToImproperPage";
// import FrAscendingPage from "../component/pages/FrAscendingPage";
// import FrImproperToMixedPage from "../component/pages/FrImproperToMixedPage"
// import FrMixedToImproperPage from "../component/pages/FrMixedToImproperPage"
// import MultiplicationTablePage from "../component/pages/MultiplicationTablePage";
// // // import MultiplicationTable from "../component/MultiplicationTable/MultiplicationTable";
// // import HMAdditionPage from "../component/pages/HMAdditionPage";
// // import CurrencyAdditionPage from "../component/pages/CurrencyAdditionPage";
// // // import CurrencyConversionForwardPage from "../pages/CurrencyConversionForwardPage";
// // import LCMPage from "../component/pages/LCMPage";
// // import TestPage from "../component/pages/TestPage";
// // import FrSubSimplePage from "../component/pages/FrSubSimplePage";
// // import FrAdditionSimplePage from "../component/pages/FrAdditionSimplePage";
// // import FrSimplification from "../component/pages/FrSimplification";
// // import FrMinification from "../component/Fraction/FrMinification";
// // import FrMinificationPage from "../component/pages/FrMinificationPage";
import React from 'react'
// import PageCard from "../../component/navigation/app/FirstPage";
// const FirstPage = React.lazy(() => import('../../component/navigation/app/FirstPage'))
// const HomePage = React.lazy(() => import('../../component/navigation/HomePage'))

const AdditionAfterArrangePage = React.lazy(() => import('../../component/pages/AdditionAfterArrangePage'))
const AdditionFourDigitPage = React.lazy(() => import('../../component/pages/AdditionFourDigitPage'))
const AdditionSimplePage = React.lazy(() => import('../../component/pages/AdditionSimplePage'))
const AdditionThreeDigitPage = React.lazy(() => import('../../component/pages/AdditionThreeDigitPage'))
const AdditionTwoDigitPage = React.lazy(() => import('../../component/pages/AdditionTwoDigitPage'))
const AscendingFiveDigitPage = React.lazy(() => import('../../component/pages/AscendingFiveDigitPage'))
const AscendingFourDigitPage = React.lazy(() => import('../../component/pages/AscendingFourDigitPage'))
const AscendingThreeDigitPage = React.lazy(() => import('../../component/pages/AscendingThreeDigitPage'))
const AscendingTwoDigitPage = React.lazy(() => import('../../component/pages/AscendingTwoDigitPage'))
const ClockPage = React.lazy(() => import('../../component/pages/ClockPage'))
const CreateSmallestBiggestNumPage = React.lazy(() => import('../../component/pages/CreateSmallestBiggestNumPage'))
const CurrencyAdditionPage = React.lazy(() => import('../../component/pages/CurrencyAdditionPage'))
const CurrencyConversionForwardPage = React.lazy(() => import('../../component/pages/CurrencyConversionForwardPage'))
const DescendingFiveDigitPage = React.lazy(() => import('../../component/pages/DescendingFiveDigitPage'))
const DescendingFourDigitPage = React.lazy(() => import('../../component/pages/DescendingFourDigitPage'))
const DescendingThreeDigitPage = React.lazy(() => import('../../component/pages/DescendingThreeDigitPage'))
const DescendingTwoDigitPage = React.lazy(() => import('../../component/pages/DescendingTwoDigitPage'))
const DivisibleBy2 = React.lazy(() => import('../../component/pages/DivisibleBy2'))
const DivisibleBy3 = React.lazy(() => import('../../component/pages/DivisibleBy3'))
const DivisibleBy5 = React.lazy(() => import('../../component/pages/DivisibleBy5'))
const DivisionAndRemainderPage = React.lazy(() => import('../../component/pages/DivisionAndRemainderPage'))
const DivisionSimplePage = React.lazy(() => import('../../component/pages/DivisionSimplePage'))
const EvenNumberPage = React.lazy(() => import('../../component/pages/EvenNumberPage'))
const FaceAndPlaceValuePage = React.lazy(() => import('../../component/pages/FaceAndPlaceValuePage'))
const FrAdditionMixedPage = React.lazy(() => import('../../component/pages/FrAdditionMixedPage'))
const FrAdditionPage = React.lazy(() => import('../../component/pages/FrAdditionPage'))
const FrAdditionSimplePage = React.lazy(() => import('../../component/pages/FrAdditionSimplePage'))
const FrAscendingPage = React.lazy(() => import('../../component/pages/FrAscendingPage'))
const FrImproperToMixedPage = React.lazy(() => import('../../component/pages/FrImproperToMixedPage'))
const FrMinificationPage = React.lazy(() => import('../../component/pages/FrMinificationPage'))
const FrMixedToImproperPage = React.lazy(() => import('../../component/pages/FrMixedToImproperPage'))
const FrSimplification = React.lazy(() => import('../../component/pages/FrSimplification'))
const FrSubMixedPage = React.lazy(() => import('../../component/pages/FrSubMixedPage'))
const FrSubPage = React.lazy(() => import('../../component/pages/FrSubPage'))
const FrSubSimplePage = React.lazy(() => import('../../component/pages/FrSubSimplePage'))
const HMAdditionPage = React.lazy(() => import('../../component/pages/HMAdditionPage'))
const Home = React.lazy(() => import('../../component/pages/Home'))
const LCMPage = React.lazy(() => import('../../component/pages/LCMPage'))
const MultiAfterArrangePage = React.lazy(() => import('../../component/pages/MultiAfterArrangePage'))
const MultiFourDigitPage = React.lazy(() => import('../../component/pages/MultiFourDigitPage'))
const MultiplicationTablePage = React.lazy(() => import('../../component/pages/MultiplicationTablePage'))
const MultiSimplePage = React.lazy(() => import('../../component/pages/MultiSimplePage'))
const MultiThreeDigitPage = React.lazy(() => import('../../component/pages/MultiThreeDigitPage'))
const MultiTwoDigitPage = React.lazy(() => import('../../component/pages/MultiTwoDigitPage'))
const NumberProblemPage = React.lazy(() => import('../../component/pages/NumberProblemPage'))
const NumberToWordPage = React.lazy(() => import('../../component/pages/NumberToWordPage'))
const OddNumberPage = React.lazy(() => import('../../component/pages/OddNumberPage'))
const SeriesPage = React.lazy(() => import('../../component/pages/SeriesPage'))
const SubAfterArrangePage = React.lazy(() => import('../../component/pages/SubAfterArrangePage'))
const SubFourDigitPage = React.lazy(() => import('../../component/pages/SubFourDigitPage'))
const SubSimplePage = React.lazy(() => import('../../component/pages/SubSimplePage'))
const SubThreeDigitPage = React.lazy(() => import('../../component/pages/SubThreeDigitPage'))
const SubTwoDigitPage = React.lazy(() => import('../../component/pages/SubTwoDigitPage'))
const TestPage = React.lazy(() => import('../../component/pages/TestPage'))
const Topic = React.lazy(() => import('../../component/pages/Topic'))
const WordToNumPage = React.lazy(() => import('../../component/pages/WordToNumPage'))


export default function pageRegister_math() {
    return [
        // {
        //     link: '/',
        //     name: 'প্রথম পাতা',
        //     component: <HomePage />
        // },
        {
            link: '/clock',
            name: 'ঘড়ি দেখে সময় লেখ',
            engName: "Write the time",
            component: <ClockPage />
        },
        // {
        //     link: '/word2num',
        //     name: 'অঙ্কে লেখ',
        //     component: <WordToNumPage />

        // },
        // {
        //     link: '/num-to-word',
        //     name: 'কথায় লেখ',
        //     component: <NumberToWordPage />
        // },
        {
            link: '/create-biggest-smallest-number',
            name: 'বৃহত্তম ও ক্ষুদ্রতম সংখ্যা তৈরি কর',
            engName: "Largest and smallest numbers",
            component: <CreateSmallestBiggestNumPage />
        },
        // {
        //     link: '/FaceAndPlaceValue',
        //     name: 'স্থানীয় ও প্রকৃতমান',
        //     engName: "Local and Face Value",
        //     component: <FaceAndPlaceValuePage />
        // },
        {
            link: '/numberProblem',
            name: 'শূণ্যস্থানে উপযুক্ত সংখ্যা বসাও',
            engName: "Fill in the gaps",
            component: <NumberProblemPage />

        },
        // {
        //     link: '/series',
        //     name: 'বিন্যাস বুঝে সংখ্যা বসাও',
        //     engName: "Fill in the gaps -2",
        //     component: <SeriesPage />

        // },
        {
            link: '/multi-table',
            name: 'গুণের নামতা',
            engName: "Multiplication Table",
            component: <MultiplicationTablePage />
        },
        // {
        //     link: '/flower',
        //     name: 'ফুলে সংখ্যা বসাও'
        // },
        {
            link: '/ascending-two-digit-page',
            name: 'ছোট থেকে বড় সাজাও \n(দুই সংখ্যার)',
            engName: "Smallest to largest\n (Two digit)",
            component: <AscendingTwoDigitPage />
        },
        {
            link: '/ascending-three-digit-page',
            name: 'ছোট থেকে বড় সাজাও\n(তিন সংখ্যার)',
            engName: "Smallest to largest \n(Three digit)",
            component: <AscendingThreeDigitPage />
        },
        {
            link: '/ascending-four-digit-page',
            name: 'ছোট থেকে বড় সাজাও\n(চার সংখ্যার)',
            engName: "Smallest to largest\n(Four digit)",
            component: <AscendingFourDigitPage />
        }, {
            link: '/ascending-five-digit-page',
            name: 'ছোট থেকে বড় সাজাও\n(পাঁচ সংখ্যার)',
            engName: "Smallest to largest\n(Five digit)",
            component: <AscendingFiveDigitPage />
        },
        {
            link: '/descending-two-digit-page',
            name: 'বড় থেকে ছোট সাজাও\n(দুই সংখ্যার)',
            engName: "Largest to smallest\n(Two digit)",
            component: <DescendingTwoDigitPage />
        },
        {
            link: '/descending-three-digit-page',
            name: 'বড় থেকে ছোট সাজাও\n(তিন সংখ্যার)',
            engName: "Largest to smallest\n(Three digit)",
            component: <DescendingThreeDigitPage />
        },
        {
            link: '/descending-four-digit-page',
            name: 'বড় থেকে ছোট সাজাও\n(চার সংখ্যার)',
            engName: "Largest to smallest\n(Four digit)",
            component: <DescendingFourDigitPage />
        }, {
            link: '/descending-five-digit-page',
            name: 'বড় থেকে ছোট সাজাও\n(পাঁচ সংখ্যার)',
            engName: "Largest to smallest\n(Five digit)",
            component: <DescendingFiveDigitPage />
        },
        {
            link: '/simple-addition',
            name: 'সহজ যোগ',
            engName: "Simple Addition",
            component: <AdditionSimplePage />
        },
        {
            link: '/two-digit-addition',
            name: 'দুই সংখ্যার যোগ',
            engName: "Addition\n(Two digit)",
            component: <AdditionTwoDigitPage />
        },
        {
            link: '/three-digit-addition',
            name: 'তিন সংখ্যার যোগ',
            engName: "Addition\n(Three digit)",
            component: <AdditionThreeDigitPage />
        },
        {
            link: '/four-digit-addition',
            name: 'চার সংখ্যার যোগ',
            engName: "Addition\n(Four digit)",
            component: <AdditionFourDigitPage />
        },
        // {
        //     link: '/arrange-to-addition',
        //     name: 'স্থানীয় মানে সাজিয়ে যোগ কর',
        //     component: <AdditionAfterArrangePage />
        // },
        {
            link: '/simple-sub',
            name: 'সহজ বিয়োগ',
            engName: "Simple Substraction",
            component: <SubSimplePage />
        },
        {
            link: '/two-digit-sub',
            name: 'দুই সংখ্যার বিয়োগ',
            engName: "Substraction\n(Two digit)",
            component: <SubTwoDigitPage />
        },
        {
            link: '/three-digit-sub',
            name: 'তিন সংখ্যার বিয়োগ',
            engName: "Substraction\n(Three digit)",
            component: <SubThreeDigitPage />
        },
        {
            link: '/four-digit-sub',
            name: 'চার সংখ্যার বিয়োগ',
            engName: "Substraction\n(Four digit)",
            component: <SubFourDigitPage />
        },
        // {
        //     link: '/arrange-to-sustract',
        //     name: 'স্থানীয় মানে সাজিয়ে বিয়োগ কর',
        //     component: <SubAfterArrangePage />

        // },
        {
            link: '/multiplication-simple',
            name: 'গুণ কর',
            engName: "Multiplication",
            component: <MultiSimplePage />
        },
        {
            link: '/multiplication-two-digit',
            name: 'দুই সংখ্যার গুণ কর',
            engName: "Multiplication\n(Two digit)",
            component: <MultiTwoDigitPage />
        },
        {
            link: '/multiplication-three-digit',
            name: 'তিন সংখ্যার গুণ কর',
            engName: "Multiplication\n(Three digit)",
            component: <MultiThreeDigitPage />
        },
        // {
        //     link: '/multiplication-four-digit',
        //     name: 'চার সংখ্যার গুণ কর',
        //     component: <MultiFourDigitPage />

        // },
        {
            link: '/division-simple',
            name: 'সহজ ভাগ',
            engName: "Simple Division",
            component: <DivisionSimplePage />
        },
        {
            link: '/division-and-remainder',
            name: 'ভাগ ও অবশিষ্ট',
            engName: "Division & Remainder",
            component: <DivisionAndRemainderPage />
        },
        {
            link: '/even-num',
            name: 'জোড় সংখ্যা',
            engName: "Even Number",
            component: <EvenNumberPage />
        },
        {
            link: '/odd-num',
            name: 'বিজোড় সংখ্যা',
            engName: "Odd Number",
            component: <OddNumberPage />
        },
        {
            link: '/divisible-by-2',
            name: 'বিভাজ্যতা-২',
            engName: "Divisibility by 2",
            component: <DivisibleBy2 />
        },
        {
            link: '/divisible-by-3',
            name: 'বিভাজ্যতা-৩',
            engName: "Divisibility by 3",
            component: <DivisibleBy3 />
        },
        {
            link: '/divisible-by-5',
            name: 'বিভাজ্যতা-৫',
            engName: "Divisibility by 5",
            component: <DivisibleBy5 />
        },
        {
            link: '/time-addition',
            name: 'ঘন্টা ও মিনিটের যোগ',
            engName: "Addition of time",
            component: <HMAdditionPage />
        },
        {
            link: '/currency-addition',
            name: 'টাকা পয়সার যোগ',
            engName: "Currency",
            component: <CurrencyAdditionPage />
        },
        // {
        //     link: '/currency-forward-conversion',
        //     name: 'টাকা পয়সার রূপান্তর',
        //     component: <CurrencyConversionForwardPage />
        // },
        {
            link: '/lcm',
            name: 'ল.সা.গু',
            engName: "L.C.M",
            component: <LCMPage />
        },
        {
            link: '/fraction-simple-addition',
            name: 'ভগ্নাংশের সহজ যোগ',
            engName: "Simple Addition of Fractions",

            component: <FrAdditionSimplePage />
        },
        {
            link: '/fraction-addition',
            name: 'ভগ্নাংশের যোগ',
            engName: "Addition of Fractions",
            component: <FrAdditionPage />
        },
        {
            link: '/fraction-simple-substraction',
            name: 'ভগ্নাংশের সহজ বিয়োগ',
            engName: "Simple Substraction of Fractions",

            component: <FrSubSimplePage />
        },
        {
            link: '/fraction-substraction',
            name: 'ভগ্নাংশের বিয়োগ',
            engName: "Substraction of Fraction",
            component: <FrSubPage />
        },
        // {
        //     link: '/fraction-ascending-equal-denominator',
        //     name: 'ভগ্নাংশের ছোট থেকে বড় সাজানো (সমান হর বিশিষ্ট)',
        //     component: <FrAscendingPage />
        // },
        // {
        //     link: '/fraction-ascending',
        //     name: 'ভগ্নাংশের ছোট থেকে বড় সাজানো*',
        //     // component: <FrAscendingPage />
        // },
        // {
        //     link: '/fraction-descending-equal-denominator',
        //     name: 'ভগ্নাংশের বড় থেকে ছোট সাজানো (সমান হর বিশিষ্ট)*',
        //     // component: <FrAscendingPage />
        // },
        // {
        //     link: '/fraction-descending',
        //     name: 'ভগ্নাংশের বড় থেকে ছোট সাজানো*',
        //     // component: <FrAscendingPage />
        // },
        // {
        //     link: '/fraction-minimal-form',
        //     name: 'লঘিষ্ঠ আকারে পরিণত কর--',
        //     component: <FrMinificationPage />
        // },
        {
            link: '/fraction-improper-to-mixed',
            name: 'অপ্রকৃত ভগ্নাংশ থেকে মিশ্র ভগ্নাংশ',
            engName: "Improper to Mixed Fraction",
            component: <FrImproperToMixedPage />
        },
        {
            link: '/fraction-mixed-to-improper',
            name: 'মিশ্র ভগ্নাংশ থেকে অপ্রকৃত ভগ্নাংশ',
            engName: "Mixed to Improper Fraction",
            component: <FrMixedToImproperPage />
        },
        // {
        //     link: '/fraction-mixed-add',
        //     name: 'মিশ্র ভগ্নাংশের যোগ--'
        // },
        // {
        //     link: '/fraction-mixed-substraction',
        //     name: 'মিশ্র ভগ্নাংশের বিয়োগ--'
        // },
        // {
        //     link: '/fraction-simplification',
        //     name: 'ভগ্নাংশের সরল',
        //     name: <FrSimplification />
        // },
        // {
        //     link: '/test-page',
        //     name: 'Test Page',
        //     component: <TestPage />
        // },
        // {
        //     link: '/test-page',
        //     name: 'Test Page',
        //     engName: "Test Page",
        //     component: <TestPage />
        // }
    ]
}