import React, { useState } from 'react'
import rangeSlider from '../../css/rangeSlider.module.css'

export default function RangeSlider() {

    const [sliderPosition, setSliderPosition] = useState(0)

    return (
        <div className={`${rangeSlider.slidecontainer} px-3 d-flex justify-content-cemter align-items-center`}>
            <input
                type="range"
                min="1"
                max="5"
                value={sliderPosition}
                className={rangeSlider.slider}
                onChange={e => setSliderPosition(e.target.value)}
                id="myRange"
            />
            {/* <div className='ms-2 fw-bold text-custom' style={{
                fontSize: 12,
               
            }}>
                {sliderPosition}
            </div> */}
        </div>
    )
}
