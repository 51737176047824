import '../../../css/color.css'
import '../../../css/WebNavigation.css'
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import pageRegister_math from "../../../page_register/math/pageRegister_math";
import useGlobalContext from '../../../hooks/useGlobalContext'
// import pageRegister from "../../../page_register/lazyPageRegister";

export default function MathPages(props) {

    let childrenLinks = []
    // const pages = pageRegister()
    const [currentState, dispatch] = useGlobalContext()

    const pages = pageRegister_math()

    const [linkNo, setLinkNo] = useState()


    return (
        <ul className=''>
            {
                pages.map((e, i) => {
                    return (
                        <Link
                            onClick={() => {
                                const menuToggle = document.querySelector('#open-sidebar-menu')
                                menuToggle.checked = false
                                setLinkNo(i)
                            }}
                            to={e.link}
                            className={`text-decoration-none border-bottom`}
                            style={{fontSize:14}}
                            key={i}
                        >
                            <li style={{
                                listStyle: 'none',
                                marginLeft: -20,
                                borderBottom:"1px solid lightgrey"
                            }}
                                className="my-1 cursor-pointer text-custom my-2" key={i}>
                                {/* {e.name} */}
                                {currentState.lang === 'bn' ? e.name : e.engName}
                            </li>
                        </Link>
                    )
                })
            }
        </ul>

    );
}
