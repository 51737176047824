const theme = {
    lightMode: {
        // colorPrimary: '#6F5193',
        // colorPrimary: '#0047AB',
        // colorPrimary: '#4169E1',
        colorPrimary: '#0039A6',
        colorSecondary: '',
        colorTertiary: '',
        colorQuaternary: '',
        colorSuccess: '#00A36C',
        // colorWarning: '#FFAA35',
        colorWarning: '#E49B0F',
        colorError: '#FF5252'
    },
    darkMode: {
        colorPrimary: '',
        colorSecondary: '',
        colorTertiary: '',
        colorQuaternary: '',
        colorSuccess: '#00A36C',
        colorWarning: '#FFAA35',
        colorError: '#FF5252'
    },
    fontFamily: {
        beng: {
            primary: '',
            secondary: '',
        },
        en: {
            primary: '',
            secondary: '',
        }
    },
    fontSize: {
        fontSizeForInstruction: 18
    }

}
export default theme;


