
const deviceType = () => {

    const userAgentData = navigator.userAgent;
    const patternAnd = /Android/i;
    const patternIPhone = /iPhone/i;
    const patternIPad = /iPad/i;

    if (userAgentData.match(patternAnd) || userAgentData.match(patternIPhone) || userAgentData.match(patternIPad)) {
        return 1;
        //1='mobile'
    } else {
        return 0;
        //0='pc'
    }
}
export default deviceType;