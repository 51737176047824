const reducer = (currentState, action) => {
    // console.log(action);
    // console.log(currentState.navigationStyle);
    switch (action.type) {
        case 'CHANGE_WEBSITE_NAME':
            return { ...currentState, websiteName: action.value }
        case 'HOME_SCROLL_POSITION':
            return { ...currentState, rememberHomePageScrollPosition: action.value }
        case 'LAST_VISITED_ROUTE':
            return { ...currentState, lastAccessedRoute: action.value }
        case 'VIBRATION':
            return { ...currentState, vibrationStrength: action.value }
        case 'AUDIO':
            return { ...currentState, audioVolume: action.value }
        case 'LANG':
            return { ...currentState, lang: action.value }
        case 'MOBILE_SETTING_MODAL':
            return { ...currentState, mobileModeSettingModalDisplay: action.value }
        case 'SETTING_MODAL':
            return { ...currentState, settingModalVisibility: action.value }
        case 'NAV_STYLE':
            return { ...currentState, navigationStyle: action.value }
        case 'SETTING_LINK_VISIBILITY':
            return { ...currentState, settingMenuVisibility: action.visible }
        case 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR':
            return { ...currentState, activeItemInAppBottomBar: action.value }


        case 'ADDITION_ONE_DIGIT':
            return { ...currentState, additionOneDigitPageNo: currentState.additionOneDigitPageNo + action.value }
        case 'ADDITION_TWO_DIGIT':
            return { ...currentState, additionTwoDigitPageNo: currentState.additionTwoDigitPageNo + action.value }
        case 'ADDITION_THREE_DIGIT':
            return { ...currentState, additionThreeDigitPageNo: currentState.additionThreeDigitPageNo + action.value }
        case 'ADDITION_FOUR_DIGIT':
            return { ...currentState, additionFourDigitPageNo: currentState.additionFourDigitPageNo + action.value }
        case 'SUBSTRACTION_ONE_DIGIT':
            return { ...currentState, substractionOneDigitPageNo: currentState.substractionOneDigitPageNo + action.value }
        case 'SUBSTRACTION_TWO_DIGIT':
            return { ...currentState, substractionTwoDigitPageNo: currentState.substractionTwoDigitPageNo + action.value }
        case 'SUBSTRACTION_THREE_DIGIT':
            return { ...currentState, substractionThreeDigitPageNo: currentState.substractionThreeDigitPageNo + action.value }
        case 'SUBSTRACTION_FOUR_DIGIT':
            return { ...currentState, substractionFourDigitPageNo: currentState.substractionFourDigitPageNo + action.value }

        case 'MULTI_TABLE':
            return { ...currentState, multiTable: currentState.multiTable + action.value }
        case 'MULTI_ONE_DIGIT':
            return { ...currentState, multiOneDigitPageNo: currentState.multiOneDigitPageNo + action.value }
        case 'MULTI_TWO_DIGIT':
            return { ...currentState, multiTwoDigitPageNo: currentState.multiTwoDigitPageNo + action.value }
        case 'MULTI_THREE_DIGIT':
            return { ...currentState, multiThreeDigitPageNo: currentState.multiThreeDigitPageNo + action.value }
        case 'DIVISION_ONLY':
            return { ...currentState, divisionOnlyPageNo: currentState.divisionOnlyPageNo + action.value }
        case 'DIVISION_AND_REMAINDER':
            return { ...currentState, divisionAndRemainderPageNo: currentState.divisionAndRemainderPageNo + action.value }
        case 'ASCENDING_TWO_DIGIT':
            return { ...currentState, ascendingTwoDigitPageNo: currentState.ascendingTwoDigitPageNo + action.value }
        case 'ASCENDING_THREE_DIGIT':
            return { ...currentState, ascendingThreeDigitPageNo: currentState.ascendingThreeDigitPageNo + action.value }
        case 'ASCENDING_FOUR_DIGIT':
            return { ...currentState, ascendingFourDigitPageNo: currentState.ascendingFourDigitPageNo + action.value }
        case 'ASCENDING_FIVE_DIGIT':
            return { ...currentState, ascendingFiveDigitPageNo: currentState.ascendingFiveDigitPageNo + action.value }

        case 'H_M_ADD':
            return { ...currentState, hourMinuteAdditionPageNo: currentState.hourMinuteAdditionPageNo + action.value }
        case 'CURRENCY_ADDITION':
            return { ...currentState, currencyAdditionPageNo: currentState.currencyAdditionPageNo + action.value }
        case 'LCM':
            return { ...currentState, lcmPageNo: currentState.lcmPageNo + action.value }

        case 'FR_ADD_SIMPLE':
            return { ...currentState, frAddSimplePageNo: currentState.frAddSimplePageNo + action.value }
        case 'FR_ADD':
            return { ...currentState, frAddPageNo: currentState.frAddPageNo + action.value }
        case 'FR_SUB_SIMPLE':
            return { ...currentState, frSubSimplePageNo: currentState.frSubSimplePageNo + action.value }
        case 'FR_SUB':
            return { ...currentState, frSubPageNo: currentState.frSubPageNo + action.value }
        case 'FR_ASCEND':
            return { ...currentState, frAscendingPageNo: currentState.frAscendingPageNo + action.value }
        case 'FR_DESCEND':
            return { ...currentState, frDescendingPageNo: currentState.frDescendingPageNo + action.value }
        case 'FR_IMPROPER_MIX':
            return { ...currentState, frImproperToMixPageNo: currentState.frImproperToMixPageNo + action.value }
        case 'FR_MIX_IMPROPER':
            return { ...currentState, frMixToImproperPageNo: currentState.frMixToImproperPageNo + action.value }

        // case 'FR_MIX_IMPROPER':
        //     return { ...currentState, frMixToImproperPageNo: currentState.frMixToImproperPageNo + action.value }
        // case 'FR_MIX_IMPROPER':
        //     return { ...currentState, frMixToImproperPageNo: currentState.frMixToImproperPageNo + action.value }
        // case 'FR_MIX_IMPROPER':
        //     return { ...currentState, frMixToImproperPageNo: currentState.frMixToImproperPageNo + action.value }
        // case 'FR_MIX_IMPROPER':
        //     return { ...currentState, frMixToImproperPageNo: currentState.frMixToImproperPageNo + action.value }

        default:
            return currentState
    }
}
export default reducer;