import React from 'react';
import useGlobalContext from "../../../hooks/useGlobalContext";
import { GlobalStateContext } from '../../../../App';
import RangeSlider from "../../rangeSlider/RangeSlider";


function Settings() {

    // const globalStateContext = React.useContext(GlobalStateContext)
    // let currentState = globalStateContext.cState
    // let dispatch = globalStateContext.dispatchDef

    const [currentState, dispatch] = useGlobalContext()


    let webChecked, appChecked;
    webChecked = currentState.navigationStyle === 0 ? true : false
    appChecked = currentState.navigationStyle === 1 ? true : false

    return (
        <div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div>
                        <label htmlFor="vibration-range" className="form-label text-custom">Vibration</label>
                        <input
                            onChange={(e) => dispatch({ type: 'VIBRATION', value: e.target.value })}
                            type="range"
                            id="vibration-range"
                            className="form-range"
                            min='0'
                            max='1'
                            step='0.25'
                            value={currentState.vibrationStrength}
                        />
                    </div>
                </li>
                {/* <li className="list-group-item">
                    <label htmlFor="sound-range" className="form-label text-custom">Sound</label>
                    <input
                        onChange={(e) => dispatch({ type: 'AUDIO', value: e.target.value })}
                        type="range"
                        id="sound-range"
                        className="form-range"
                        min='0'
                        max='1'
                        step='0.25'
                        value={currentState.audioVolume}
                    />
                </li> */}

                {/* <li className="list-group-item">
                        <div>
                            <label htmlFor="sound-range" className="form-label text-custom">Language</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type="radio"
                                name="navigation-style"
                                id="navigationStyle1"
                                value="bn"
                                defaultChecked={currentState.lang === 'bn' ? 'checked' : ''}
                                onChange={e => dispatch({ type: 'LANG', value: 'bn' })}
                            />
                            <label className="form-check-label" htmlFor="navigationStyle1">Bengali</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type="radio"
                                name="navigation-style"
                                id="navigationStyle2"
                                value="en"
                                defaultChecked={currentState.lang === 'en' ? 'checked' : ''}
                                onChange={e => dispatch({ type: 'LANG', value: 'en' })}
                            />
                            <label className="form-check-label" htmlFor="navigationStyle2">English</label>
                        </div>
                    </li> */}

                <li className="list-group-item">
                    <div>
                        <label htmlFor="sound-range" className="form-label text-custom">Navigation Style</label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input className='form-check-input' type="radio" name="navigation-style" id="navigationStyle1" value="app" defaultChecked={appChecked} onChange={() => dispatch({ type: 'NAV_STYLE', value: 1 })} />
                        <label className="form-check--label text-custom" htmlFor="navigationStyle1">App</label>
                    </div>
                    <div className='form-check form-check-inline'>
                        <input className='form-check-input' type="radio" name="navigation-style" id="navigationStyle2" value="web" defaultChecked={webChecked} onChange={() => dispatch({ type: 'NAV_STYLE', value: 0 })} />
                        <label className="form-check--label text-custom" htmlFor="navigationStyle2">Web</label>
                    </div>
                </li>
                <li>
                    <RangeSlider />
                </li>
                {/* <li className="list-group-item">
                    <label htmlFor="sound-range" className="form-label text-custom">Language</label>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="language" id="bn" defaultChecked />
                        <label className="form-check-label" htmlFor="bn">
                            Bengali
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="language" id="en" />
                        <label className="form-check-label" htmlFor="en">
                            English
                        </label>
                    </div>
                </li> */}
                {/* <li className="list-group-item">
                    <div>
                        <label htmlFor="sound-range" className="form-label text-custom">Theme</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked />
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Light</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                        <label className="form-check-label" htmlFor="inlineCheckbox2">Dark</label>
                    </div>
                </li> */}
            </ul>
        </div>
    );
}

export default Settings;