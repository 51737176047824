import React, { useContext } from 'react';
// import pageRegister from "../../../page_register/pageRegister";

import pageRegister_eng from '../../page_register/eng/pageRegister_eng'
import pageRegister_math from '../../page_register/math/pageRegister_math'

import useGlobalContext from "../../hooks/useGlobalContext";
import { Link, redirect } from "react-router-dom";
import { ConfigContext } from "../../AppContext";
import strings from '../../assets/strings/strings'



export default function FirstPage(props) {

    const [currentState, dispatch] = useGlobalContext()


    const config = useContext(ConfigContext)
    const deviceDetected = currentState.navigationStyle
    // const [pages, fontStyle] = currentState.activeItemInAppBottomBar === 'math' ? [pageRegister_math(), 'fnt-Nikosh'] : [pageRegister_eng(), ""]
    // const containerClassNames = deviceDetected === 0 ? 'd-flex justify-content-center flex-wrap mt-5' : 'd-flex justify-content-evenly flex-wrap'

    let pages, fontStyle;
    if (currentState.activeItemInAppBottomBar === 'math') {
        pages = pageRegister_math()
        if (currentState.lang === 'bn') { fontStyle = 'fnt-Nikosh' }
    } else if (currentState.activeItemInAppBottomBar === 'eng') {
        pages = pageRegister_eng()
        fontStyle = ''
    }
    let containerClassNames;

    if (deviceDetected === 0) {
        containerClassNames = 'd-flex justify-content-center flex-wrap mt-5'
    } else if (deviceDetected === 1) {
        containerClassNames = 'd-flex justify-content-evenly flex-wrap'
    }



    const WelcomeMsg = () => {
        return (
            <div className='d-flex-column justify-content-center mt-5'>
                <h1 className='text-muted text-center mt-5'>Welcome</h1>
                <h5 className='text-muted text-center'>Please choose a topic from the menu</h5>
            </div>
        )
    }


    const myRef = React.useRef(null)

    React.useEffect(() => {
        // console.log("Exe");
        myRef.current.scrollTo(
            {
                top: currentState.rememberHomePageScrollPosition,
                left: 0,
                behavior: "auto"
            }
        )
    }, [])


    const onScrollManager = () => {
        const scrollTop = myRef.current.scrollTop
        dispatch({ type: 'HOME_SCROLL_POSITION', value: scrollTop })
    }

    return (
        <div
            ref={myRef}
            onScroll={onScrollManager}
            className={`${containerClassNames}`}
            style={{
                width: "100%",
                height: "100%",
                overflow: "scroll"
            }}
        >
            {
                deviceDetected === 0 ? <WelcomeMsg /> :
                    pages.map((el, i) => (
                        <Link to={pages[i].link} className={`text-decoration-none ${fontStyle}`} key={i}>
                            <div
                                className="items d-flex justify-content-center align-items-center text-center text-custom cursor-pointer mx-2 my-3 p-2 shadow-lg"
                                key={i}
                                style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    backgroundColor: 'lightgrey'
                                }}
                            >
                                {/* {pages[i].name} */}
                                {currentState.lang === "bn" ? pages[i].name : pages[i].engName}
                            </div>
                        </Link>
                    ))
            }
        </div>
    )
}