import './App.css';
import './dev/custom.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Profiler, Suspense, useContext, useEffect, useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import config from './dev/Config/config';
// import { isCookieSet, getCookie, setCookie } from "./dev/lib/cookie";
import deviceType from "./dev/lib/deviceType";
// import FirstPage from "./dev/component/navigation/app/FirstPage";
import initialState from "./dev/state_manager/store";
import reducer from "./dev/state_manager/reducer";
import { stateHandeler } from "./dev/lib/reactStateManupulationLib";
import { GlobalStateContext, ConfigContext } from "./dev/AppContext";
import pageRegister from './dev/page_register/pageRegister'
import AppNavigation from './dev/component/navigation/app/AppNavigation'
import AppNavigationV2 from './dev/component/navigation/app/AppNavigationV2'
import WebNavigationV2 from './dev/component/navigation/web/WebNavigationV2'
import Footer from './dev/component/navigation/Footer'
import AppBottomBar from './dev/component/navigation/app/AppBottomBar'


const routes = () => {
  let routes = []
  const routeObject = pageRegister()
  for (const key in routeObject) {
    routeObject[key].forEach((el, i) => {
      routes.push(<Route path={el.link} element={el.component} key={i} />)
    });
  }
  return routes
}

export default function App() {

  // const [currentState, dispatch] = useReducer(reducer, JSON.parse(localStorage.getItem('PERSIST_STATE')) || initialState)
  const [currentState, dispatch] = useReducer(reducer, stateHandeler(initialState, JSON.parse(localStorage.getItem('PERSIST_STATE'))))

  useEffect(() => {
    localStorage.setItem('PERSIST_STATE', JSON.stringify(currentState))
  }, [currentState])

  useEffect(() => {
    // dispatch({ type: 'NAV_STYLE', value: deviceType() })
    dispatch({ type: 'NAV_STYLE', value: 1 })

  }, [currentState.navigationStyle])

  useEffect(() => {
    dispatch({ type: 'CHANGE_WEBSITE_NAME', value: "primarymath.in" })

  }, [currentState.websiteName])

  // console.log(currentState.navigationStyle);

  let blurEffect, navStyle, classString, invisibleVeilAppearance;
  blurEffect = currentState.settingModalVisibility === 1 ? 'blur-effect' : ''
  invisibleVeilAppearance = currentState.settingModalVisibility === 1 ? '' : 'd-none'

  if (currentState.navigationStyle === 1) {
    navStyle = <AppNavigationV2 />
    classString = 'app-container d-flex justify-content-center flex-wrap mb-3'
  } else if (currentState.navigationStyle === 0) {
    // navStyle = <WebNavigation />
    navStyle = <WebNavigationV2 />
    classString = 'content-box-web'
    blurEffect = ''
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <GlobalStateContext.Provider value={{ cState: currentState, dispatchDef: dispatch }}>
          <ConfigContext.Provider value={config}>
            {navStyle}
            {/* <AppNavigationV2 /> */}
            {/* <Profiler id="main" onRender={logTimes}> */}

            <main
              className={`${classString} ${blurEffect}`}
              style={{
                overflow: "auto",
              }}
            >
              {/* <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 0,
                }}
                className={`${invisibleVeilAppearance}`}
              >
              </div> */}
              <Routes>{routes()}</Routes>
            </main>
            {/* </Profiler> */}
            {/* <AppBottomBar /> */}
            {/* <Footer /> */}
          </ConfigContext.Provider>
        </GlobalStateContext.Provider>
      </Suspense>
    </BrowserRouter >
  );
}
