import React from 'react';
import useGlobalContext from "../../hooks/useGlobalContext";
import Settings from '../navigation/web/Settings';

export default function SettingModal(props) {

    const [currentState, dispatch] = useGlobalContext()

    let webChecked, appChecked;
    webChecked = currentState.navigationStyle === 0 ? true : false
    appChecked = currentState.navigationStyle === 1 ? true : false

    return (
        <div
            className='modal-class rounded border border-muted shadow-sm bg-custom'
            style={{
                width: 300,
                height: 100
            }}
        >
            <div>
                <div className='d-flex justify-content-between mx-2'>
                    <div className='text-light ' style={{ fontSize: '17px' }}>Setting</div>
                    <div className='text-light cursor-pointer px-3' onClick={() => dispatch({ type: 'SETTING_MODAL', value: 0 })}>X</div>
                </div>
                <ul className="list-group list-group-flush">
                    {/* <li className="list-group-item">
                        <div>
                            <label htmlFor="vibration-range" className="form-label text-custom">Vibration</label>
                            <input
                                onChange={(e) => dispatch({ type: 'VIBRATION', value: e.target.value })}
                                type="range"
                                id="vibration-range"
                                className="form-range"
                                min='200'
                                max='1000'
                                step='200'
                                value={currentState.vibrationStrength}
                            />
                        </div>
                    </li> */}
                    <li className="list-group-item">
                        <div>
                            <label htmlFor="language" className="form-label text-custom">Language</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type="radio"
                                name="language-option"
                                id="bengali-lang"
                                value="bn"
                                defaultChecked={currentState.lang == 'bn' ? true : false}
                                // defaultChecked={true}

                                onChange={() => dispatch({ type: 'LANG', value: 'bn' })} />
                            <label className="form-check--label" htmlFor="bengali-lang">Bengali</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input
                                className='form-check-input'
                                type="radio"
                                name="language-option"
                                id="english-lang"
                                value="en"
                                defaultChecked={currentState.lang == 'en' ? true : false}
                                // defaultChecked={false}

                                onChange={() => dispatch({ type: 'LANG', value: 'en' })} />
                            <label className="form-check--label" htmlFor="english-lang">English</label>
                        </div>
                    </li>
                    {/* <li className="list-group-item">
                        <div>
                            <label htmlFor="sound-range" className="form-label text-custom">Navigation Style</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input className='form-check-input' type="radio" name="navigation-style" id="navigationStyle1" value="app" defaultChecked={appChecked} onChange={() => dispatch({ type: 'NAV_STYLE', value: 1 })} />
                            <label className="form-check--label text-custom" htmlFor="navigationStyle1">App</label>
                        </div>
                        <div className='form-check form-check-inline'>
                            <input className='form-check-input' type="radio" name="navigation-style" id="navigationStyle2" value="web" defaultChecked={webChecked} onChange={() => dispatch({ type: 'NAV_STYLE', value: 0 })} />
                            <label className="form-check--label text-custom" htmlFor="navigationStyle2">Web</label>
                        </div>
                    </li> */}
                </ul>
            </div>
        </div>
    );
}

