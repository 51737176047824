import pageRegister_eng from './eng/pageRegister_eng'
import pageRegister_math from './math/pageRegister_math'
import HomePage from '../component/navigation/HomePage'

// console.log(pageRegister_eng().concat(pageRegister_math()));


export default function pageRegister() {
    const pageRegister = {}
    pageRegister.home = [
        {
            link: '/',
            name: 'প্রথম পাতা',
            component: <HomePage />
        }]
    pageRegister.eng = pageRegister_eng()
    pageRegister.math = pageRegister_math()

    return pageRegister
    // return pageRegister_eng().concat(pageRegister_math())
}
