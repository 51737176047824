import React from 'react';
import { GlobalStateContext } from "../AppContext";

function useGlobalContext(props) {
    const globalStateContext = React.useContext(GlobalStateContext)
    // let currentState = globalStateContext.cState
    // let dispatch = globalStateContext.dispatchDef
    // const [currentState, dispatch] = useGlobalContext()


    return [globalStateContext.cState, globalStateContext.dispatchDef]
}

export default useGlobalContext;