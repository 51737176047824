import React from 'react'
import { BrowserRouter, Routes, Route, useNavigate, useLocation, Link } from "react-router-dom";
import BackArrow from "../../../assets/svg/leftarrow-icon.svg";
import Home from "../../../assets/svg/home-icon -modified.svg";
import Gear from "../../../assets/svg/gear-icon-modified.svg";
import useGlobalContext from "../../../hooks/useGlobalContext";
import SettingModal from "../../modal/SettingModal";
import AppBottomBar from './AppBottomBar'
import pageRegister_eng from '../../../page_register/eng/pageRegister_eng'
import pageRegister_math from '../../../page_register/math/pageRegister_math'
import pageRegister from "../../../page_register/pageRegister";
import second from '../../../assets/strings/strings'


export default function AppNavigationV2() {

    const [currentState, dispatch] = useGlobalContext()

    const navigate = useNavigate()
    const { pathname, ...rest } = useLocation()

    let name;
    const routeObject = pageRegister()
    for (const key in routeObject) {
        routeObject[key].forEach((el, i) => {
            // console.log(el);
            if (el.link === pathname) {
                // name = el.name
                name = currentState.lang === "bn" ? el.name : el.engName

            }
        });
    }

    const isAppbarDisplayed = 0
    let settingModalDisplay = currentState.settingModalVisibility
    let modalVisibility = settingModalDisplay === 0 ? 'd-none' : ''
    // console.log('modalVisibility: ', modalVisibility);
    // console.log('settingModalDisplay: ', settingModalDisplay);



    const AppBar = () => {
        return (
            <>
                <div
                    // className={`app-bar position-fixed top-0 start-0 ps-4 pe-4 mb-5 d-flex justify-content-between text-light bg-custom ${isAppbarDisplayed}`}
                    className={`app-bar ps-4 pe-4 mb-5 d-flex justify-content-between text-light bg-custom ${isAppbarDisplayed}`}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0
                    }}
                >
                    <div className='app-name'>{currentState.websiteName}</div>
                    <div className='app-menu-items cursor-pointer' onClick={() => dispatch({ type: 'SETTING_MODAL', value: 1 })}>
                        <img src={Gear} width="25" height="25" />
                    </div>
                  
                    {/* <div className='app-menu-items cursor-pointer' onClick={() => dispatch({ type: 'SETTING_MODAL', value: 1 })}>
                        <img src={Gear} width="25" height="25" />
                    </div> */}
                </div>
                <div className='app-design-div bg-custom'></div>
                <div className={`${modalVisibility} `}>
                    <SettingModal />
                </div>

            </>
        )
    }

    const fontFamily = currentState.lang === "bn" ? "fnt-Nikosh" : "";
    const isHeadingbarDisplayed = 0
    const HeadingBar = () => {
        return (
            <div className={`app-bar position-fixed top-0 start-0 ps-4 pe-4 d-flex justify-content-between text-light bg-custom ${isHeadingbarDisplayed}`}>
                <div className='app-heading d-flex align-items-center'>
                    <div style={{ marginBottom: '9px' }} className='app-menu-items cursor-pointer mb-2' onClick={() => navigate(-1)}>
                        <img src={BackArrow} width="25" height="25" />
                    </div>
                    <div
                        // className='app-menu-items ms-2 fnt-Nikosh incomplete-sentence'
                        className={`app-menu-items ms-2 incomplete-sentence ${fontFamily}`}
                        style={{
                            fontSize: 15
                        }}
                    >
                        {name}
                    </div>
                </div>
                <div className='app-menu-items cursor-pointer'>
                    <Link to='/' >
                        <img src={Home} width="25" height="25" />
                    </Link>
                </div>
            </div>
        )
    }

    return pathname === '/' ? <AppBar /> : <HeadingBar />
}
