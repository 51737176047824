import React from 'react'
import minus from '../../assets/svg/dash-circle-fill.svg'
import plus from '../../assets/svg/plus-circle-fill.svg'




export default function Accordion({ data }) {
    console.log(data);

    // const data = [
    //     {
    //         header: 'English',
    //         // body: <PageList />
    //         body: "English"
    //     },
    //     {
    //         header: 'Settings',
    //         // body: <Settings />
    //         body: "Settings"
    //     }
    // ]
    const [state, setState] = React.useState(null)


    const bodyManager = index => state != index ? 'd-none' : ""
    const symbolManager = index => state != index ? <img src={plus} height={20} width={20} /> : <img src={minus} height={20} width={20} />
    // const symbolManager = index => state != index ? '+' : 'x'
    const clickManager = index => setState(state === index ? null : index)

    // useEffect(() => {
    //     const accordionHeader = document.getElementById('accordion-header')
    //     const accordionContainer = document.getElementById('accordion-container')

    //     // console.log(accordionHeader.clientHeight);
    //     console.log(accordionContainer.clientHeight);


    // })

    return (
        <>
            {
                data.map((e, i) => {
                    return (
                        <div key={`accordion${i}`} id='accordion-container'>
                            <div
                                id='accordion-header'
                                style={accordionStyle.headerStyle}
                                className='text-custom border-bottom border-top cursor-pointer'
                                onClick={() => clickManager(i)}

                            >
                                <div className='d-flex justify-content-between pt-1'>
                                    <div>{e.header}</div>
                                    <div
                                        style={accordionStyle.symbolStyle}
                                    >
                                        {symbolManager(i)}
                                    </div>
                                </div>
                            </div>
                            <div style={accordionStyle.bodyStyle} className={`${bodyManager(i)} `}>
                                {e.body}
                            </div>
                        </div>
                    )

                })
            }

        </>
    );
}
const accordionStyle = {
    headerStyle: {
        width: 250,
        height: '90%',
        fontSize: 17,
        fontWeight: 500,
        paddingInline: 10,
        paddingBottom: 4
    },
    symbolStyle: {
        cursor: 'pointer',
        fontSize: 17,
        fontWeight: 'bold'

    },
    bodyStyle: {
        height: 300,
        overflowY: 'auto',
    }
}