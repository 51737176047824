import React from 'react';
import num from "../../../assets/svg/number.svg";
import add from "../../../assets/svg/addition.svg";
import multi from "../../../assets/svg/multiplication.svg";
import sub from "../../../assets/svg/substraction-minus-svgrepo-com (1).svg";
import division from "../../../assets/svg/divide.svg";
import fraction from "../../../assets/svg/division-svgrepo-com.svg";
import setting from '../../../assets/svg/gear-icon-modified.svg'
import eng from '../../../assets/img/chalkboard.png'
import math from '../../../assets/img/mathematics.png'
// import pageRegister from "../pageRegister1";
// import pageRegister from "../../../page_register/pageRegister";
import pageRegister_eng from '../../../page_register/eng/pageRegister_eng'
import pageRegister_math from '../../../page_register/math/pageRegister_math'
import useGlobalContext from "../../../hooks/useGlobalContext";
import { useLocation } from 'react-router-dom'

function AppBottomBar(props) {

    const { pathname, ...rest } = useLocation()

    const [currentState, dispatch] = useGlobalContext()

    if (currentState.navigationStyle === 0) { return }
    if (pathname !== '/') { return }
    const mathTabFontColr = currentState.activeItemInAppBottomBar === 'math' ? 'yellow' : 'white'
    const engTabFontColr = currentState.activeItemInAppBottomBar === 'eng' ? 'yellow' : 'white'


    return (
        <div
            className="app-bottom-bar d-flex align-items-center"
            style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                marginBottom: 10
            }}
        >
            <div className="app-menu-bar align-items-center d-flex justify-content-evenly bg-custom ms-2 me-2 rounded">
                <div
                    className="bottom-menu-items cursor-pointer"
                    style={{
                        width: 50,
                        fontSize: 10,
                        textAlign: "center",
                        color: mathTabFontColr
                    }}
                    // onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'arithmetic_operations' })}
                    onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'math' })}
                >
                    {/* <img
                        src={math}
                        height="25"
                        width="25"
                    /> */}
                    
                    Practice
                </div>
                <div
                    className="bottom-menu-items cursor-pointer"
                    style={{
                        width: 50,
                        fontSize: 10,
                        // border: "1px solid red",
                        textAlign: "center",
                        color: engTabFontColr
                    }}
                    // onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'short_questions' })}
                    onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'eng' })}
                >
                    {/* <img src={eng} height="25" width="25" /> */}
                    {/* Eng */}
                    Short
                </div>
                <div
                    className="bottom-menu-items cursor-pointer"
                    style={{
                        width: 50,
                        fontSize: 10,
                        // border: "1px solid red",
                        textAlign: "center",
                        color: engTabFontColr
                    }}
                    onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'practical_problems' })}
                >
                    {/* <img src={eng} height="25" width="25" /> */}
                    {/* Eng */}
                    Problems
                </div>
                {/* <div
                    className="bottom-menu-items cursor-pointer"
                    onClick={() => dispatch({ type: 'ACTIVE_ITEM_IN_APP_BOTTOM_BAR', value: 'setting' })}
                >
                    <img src={setting} height="20" width="20" />
                </div> */}

            </div>
        </div>
    );
}

export default AppBottomBar;